import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap"
import BreadCrumb from "../Common/BreadCrumb"
import { ToastContainer } from "react-toastify"
import { OfferFilterAccordion } from "./OfferFilterAccordion"
import React, { useEffect, useState } from "react"
import { PAGE_TITLES } from "../Common/constants"
import { toggleAccordion } from "../OrderManagement/constants/OrderFilterFunctions"
import { filteredResultFunction, groupedFunction, offerServiceData } from "./constants/offerServiceData"
import { Loader } from "../Utils/Loader"
import CommonButton from "../Utils/CommonButton"
import CommonModal from "../Utils/CommonModal"
import { onSubmitCustomerFunction } from "./constants/DocumentTableFunctions"
import { toggleModal, toggleTab } from "./constants/TelcoSubmitFunctions"
import { customerIdData } from "./constants/constantFunctionsView"
import { tabChange } from "../Utils/SetDefaultRowData"
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom"
import { handleRadioButtonChange } from "./constants/SimulateFunctions"
import { submitChangePlan } from "./constants/ChangePlanSubmitFunction"
import { useDispatch } from "react-redux"

export const SubscriptionWhileCustomerCreation = ({ navigate, values, isEdit, subscriptionsLength, setParentLoading, dispatch, customerId, dialCode, customerPermission, roleName, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue, searchableValues, nonSearchableValues }) => {
    const location = useLocation()
    const customerIdPath = customerIdData()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState('1');
    const toggle = toggleAccordion(open, setOpen)
    const [result, setResult] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startsOn, setStartsOn] = useState(null)
    const [endsOn, setEndsOn] = useState(null)
    const { offerResponse, serviceResponse, customerData } = offerServiceData(setLoading, customerIdPath);
    const [modal, setModal] = useState(false);
    const [activetab, setactivetab] = useState("1")
    const [selectedOfferCode, setSelectedOfferCode] = useState()
    const [selectedOfferName, setSelectedOfferName] = useState()
    const offerCode = location?.state?.offerCode
    const subId = location?.state?.subscriptionIdOfCustomer
    const navigateToView = useNavigate()
    const [immediate, setImmediate] = useState(false)
    const [immediateMinus, setImmediateMinus] = useState(false)
    const [nextBillCycle, setNextBillCycle] = useState(false)
    const [offerAttributes, setOfferAttributes] = useState()
    const dispatchForChangePlan = useDispatch()
    const [condition, setCondition] = useState(false)
    const [isChangeOffer, setIsChangeOffer] = useState(false)

    useEffect(() => {
        groupedFunction(offerResponse, serviceResponse, setResult, customerIdPath, customerData)
        if (immediate) {
            handleRadioButtonChange("Immediate", setImmediate, setImmediateMinus, setNextBillCycle)
        } else if (immediateMinus) {
            handleRadioButtonChange("Immediate Minus Used", setImmediate, setImmediateMinus, setNextBillCycle);
        } else if (nextBillCycle) {
            handleRadioButtonChange("Next Bill Cycle", setImmediate, setImmediateMinus, setNextBillCycle);
        }
    }, [offerResponse, serviceResponse, immediate, immediateMinus, nextBillCycle]);

    const filteredResults = customerIdPath === "create-customer" ? filteredResultFunction(result, searchTerm) : filteredResultFunction(result?.baseOffers, searchTerm)

    return (
        <React.Fragment>
            {loading && (<Loader data-testid="loader" />)}
            <div id='csms' className={customerIdPath !== "create-customer" && "page-content"}>
                <Container fluid>
                    <ToastContainer position='top-center' />
                    {customerIdPath === "create-customer" ? <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className='row mt-3'>
                                        <div className='col-xl-9 col-md-8 text-md-end'>
                                            <div className='search-box'>
                                                <span className='ri-search-line search-icon'></span>
                                                <input
                                                    type='text'
                                                    className='form-control bg-light'
                                                    placeholder='Search...'
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <OfferFilterAccordion setOfferAttributes={setOfferAttributes} immediate={immediate} setImmediate={setImmediate} immediateMinus={immediateMinus} setImmediateMinus={setImmediateMinus} nextBillCycle={nextBillCycle} setNextBillCycle={setNextBillCycle} offerCode={offerCode} customerIdPath={customerIdPath} setSelectedOfferCode={setSelectedOfferCode} startsOn={startsOn} setStartsOn={setStartsOn} endsOn={endsOn} setEndsOn={setEndsOn} open={open} toggle={toggle} filteredResults={filteredResults} loading={loading} navigate={navigate} />
                                </CardBody>
                            </Card>
                            <Row lg={6} className=" justify-content-end mb-4">
                                <div className="live-preview">
                                    <div className="d-flex justify-content-end gap-2 ">
                                        <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => toggleTab(1, 0, activeTab, passedSteps, setactiveTab, setPassedSteps, setprogressbarvalue)} buttonText={"Back"} />
                                        <CommonButton color={"main-color"} type={"button"} buttonAction={() => {
                                            if (startsOn) { onSubmitCustomerFunction(values, isEdit, subscriptionsLength, setParentLoading, dispatch, customerId, navigate, dialCode, roleName, searchableValues, nonSearchableValues, startsOn, endsOn, selectedOfferCode) }
                                            else { toggleModal(setModal, modal) }
                                        }} buttonText={"Confirm"} />
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </Row> : <Card>
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activetab === "1" })}
                                        onClick={() => tabChange("1", activetab, setactivetab)}>
                                        <i className="fas fa-home"></i>
                                        Base
                                    </NavLink>
                                </NavItem></Nav>
                        </CardHeader>
                        <CardBody>
                            <div className='row mt-3'>
                                <div className='col-xl-9 col-md-8 text-md-end'>
                                    <div className='search-box'>
                                        <span className='ri-search-line search-icon'></span>
                                        <input
                                            type='text'
                                            className='form-control bg-light'
                                            placeholder='Search...'
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                        <CardBody>
                            <OfferFilterAccordion setSelectedOfferName={setSelectedOfferName} setOfferAttributes={setOfferAttributes} immediate={immediate} setImmediate={setImmediate} immediateMinus={immediateMinus} setImmediateMinus={setImmediateMinus} nextBillCycle={nextBillCycle} setNextBillCycle={setNextBillCycle} offerCode={offerCode} customerIdPath={customerIdPath} setSelectedOfferCode={setSelectedOfferCode} startsOn={startsOn} setStartsOn={setStartsOn} endsOn={endsOn} setEndsOn={setEndsOn} open={open} toggle={toggle} filteredResults={filteredResults} loading={loading} navigate={navigate} />
                        </CardBody>
                    </Card>}
                    {customerIdPath !== "create-customer" &&
                        <Row lg={6} className=" justify-content-end mb-4">
                            <div className="live-preview">
                                <div className="d-flex justify-content-end gap-2 ">
                                    <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => { navigateToView(`/view-customer/${customerIdPath}`) }} buttonText={"Cancel"} />
                                    <CommonButton color={"main-color"} type={'submit'} buttonAction={() => {
                                        setIsChangeOffer(true)
                                        toggleModal(setModal, modal)
                                    }} buttonText={"Confirm"} />
                                </div>
                            </div>
                        </Row>
                    }
                </Container>
            </div>
            <div>
                <CommonModal messageForPopUp={isChangeOffer ? `Are you sure you want to change you offer to "${selectedOfferCode}"` : 'Do you want to create a customer without subscription?'} closeButtonAction={() => { toggleModal(setModal, modal) }} closeButtonText={!isChangeOffer && "No, Subscribe Offer"} fromCustomer={!isChangeOffer && "Customer"} toggle={() => { toggleModal(setModal, modal) }} open={modal} buttonText={"Yes, Proceed"} modalAction={() => {
                    if (!isChangeOffer) {
                        toggleModal(setModal, modal)
                        onSubmitCustomerFunction(values, isEdit, subscriptionsLength, setParentLoading, dispatch, customerId, navigate, dialCode, roleName, searchableValues, nonSearchableValues)
                    }
                    else {
                        submitChangePlan(customerIdPath, selectedOfferCode, subId, immediate, immediateMinus, nextBillCycle, setLoading, dispatchForChangePlan, setCondition)
                        navigateToView(`/change-offer-summary/${customerIdPath}`, { state: { customerData: customerData, id: { original: { CustomerId: customerIdPath } }, attributes: offerAttributes, offerName: selectedOfferName, condition: condition } })
                    }
                }} />
            </div>
        </React.Fragment>
    )
}