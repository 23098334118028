import React, { useState } from 'react';
import { FieldArray } from 'formik';
import { Card, CardBody, CardHeader, Button, Row, Col, Label } from 'reactstrap';
import InputField from '../../Utils/InputField';
import SelectField from '../../Utils/SelectField';
import { Link } from 'react-router-dom';
import { handleGrantsAndChargesResourceValueAndUsageFields, handleGrantsAndChargesRecurringFrequencyAndUsageOptions, isEmptyOrInvalidFieldForPricingModel } from './constants/GrantsAndRecurringFunctions';
import PricingModelTable from './PricingModelTable';
import { pricingModelOptions } from './constants/initialValues';
import UsageRatePlanRow from './UsageRatePlanRow';

export default function UsageRatePlan({
    values,
    errors,
    isEdit,
    touched,
    setFieldTouched,
    setFieldValue,
    setTouched,
    isEmptyOrInvalidField,
    handleChange,
    resourceNameOptions,
    uomOptions,
}) {
    const [showPricingModel, setShowPricingModel] = useState(Array(values.usageRatePlan.length).fill(false));
    
    const togglePricingModel = (index) => {
        setShowPricingModel(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    return (
        <FieldArray name="usageRatePlan">
            {({ push, remove }) => (
                <Card>
                    <CardHeader className="align-items-center justify-content-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1 me-2">New Usage Rate Plan</h4>
                        {!isEdit && (
                            <Button
                                color="secondary"
                                className="btn-label"
                                onClick={() => {
                                    const lastIndex = values.usageRatePlan.length - 1;
                                    if (lastIndex === -1 || !isEmptyOrInvalidField(lastIndex, values)) {
                                        push({
                                            usageId: "",
                                            eventType: '',
                                            resourceName: '',
                                            unitOfMeasurement: '',
                                            priority: "1",
                                            perUnitRate: '',
                                            flatRate: '',
                                            pricingModel: [],
                                            pricingModelNew: [],
                                            validityStart:'',
                                            validityEnd:'',
                                        });
                                        setTouched({
                                            ...touched,
                                            usageRatePlan: {
                                                ...touched.usageRatePlan,
                                                [lastIndex]: {
                                                    usageId: false,
                                                    eventType: false,
                                                    resourceName: false,
                                                    unitOfMeasurement: false,
                                                    priority: false,
                                                    perUnitRate: false,
                                                    flatRate: false,
                                                    pricingModel: false,
                                                    validityStart:false,
                                                    validityEnd:false
                                                },
                                            },
                                        });
                                    }
                                }}
                            >
                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Usage
                            </Button>
                        )}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            {values?.usageRatePlan?.length === 0 ? (
                                <div className="text-center">No Usage Rate Plans</div>
                            ) : (
                                values?.usageRatePlan.map((val, index) => (
                                    <div key={index} className="border border-opacity-50 rounded mb-3 p-3">
                                        <Row className="mb-3">
                                            <Col xs={12} className="d-flex justify-content-between align-items-center border-bottom p-2">
                                                <h3 className="card-title mb-0">Usage Rate Plan {index + 1}</h3>
                                                {!isEdit && (
                                                    <Link to="#" className="link-danger" onClick={() => remove(index)}>
                                                        <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                                                    </Link>
                                                )}
                                            </Col>
                                        </Row>
                                        <UsageRatePlanRow
                                            index={index}
                                            val={val}
                                            isEdit={isEdit}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleGrantsAndChargesResourceValueAndUsageFields={handleGrantsAndChargesResourceValueAndUsageFields}
                                            handleGrantsAndChargesRecurringFrequencyAndUsageOptions={handleGrantsAndChargesRecurringFrequencyAndUsageOptions}
                                            resourceNameOptions={resourceNameOptions}
                                            uomOptions={uomOptions}
                                            pricingModelOptions={pricingModelOptions}
                                            togglePricingModel={togglePricingModel}
                                            remove={remove}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                        {val.pricingModel?.value === 'volume' && showPricingModel[index] && (
                                            <PricingModelTable
                                                values={values}
                                                errors={errors}
                                                isEdit={isEdit}
                                                touched={touched}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                setTouched={setTouched}
                                                handleChange={handleChange}
                                                index={index}
                                                togglePricingModel={togglePricingModel}
                                                setShowPricingModel={setShowPricingModel}
                                                onClose={() => setShowPricingModel(false)}
                                            />
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    </CardBody>
                </Card>
            )}
        </FieldArray>
    );
}
